<template>
  <div class="form">

    <div class="form__input">
      <p class="form__input__textarea-p">{{ contact_result }}</p>
      <textarea class="form__input__textarea" :style="error ? 'border-color:#FF3300': ''" v-model="contact_result" />
      <span :style="error ? 'color:#FF3300': ''">{{$root.langText.changes.resultContact}}</span>
    </div>


    <span style="font-size: 12px;color: #8EB6E3;font-weight: 600;line-height: 2.3;">{{$root.langText.changes.scheduleContact}}</span>
    <div class="form__row" style="justify-content: space-between;">
      <div style="display:flex;">
        <DataInput
          :data="{
            data: data,
            input: 'data',
            mod: 'dataTime',
            min: new Intl.DateTimeFormat('ru').format(new Date()),
          }"
          @resultData="dataInput"
        />
      </div>
      <div style="margin-left: 20px;margin-top: -15px">
      <a
        class="btn-save btn btn-primary"
        href="#"
        @click.prevent="sendForm"
        >{{$root.langText.save}}</a
      >
      </div>

    </div>


    <h2 v-if="tasks.length !== 0" style="margin-bottom:0">{{$root.langText.directory.quotes}}</h2>



    <a class="form__tasks"
      v-for="(item, index) in tasks"
      :key="index"
      :data-id="item.id"
      @click.prevent="openPopup(item, 'tasks')"
    >
      <div class="form__tasks__title">
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" 
            :fill="+(new Date(item.taskDateFrom)) < +(new Date().setHours(0,0,0,0)) ? '#FF3300': '#079941'">
            <g clip-path="url(#clip0)"><path d="M15.2 3.4C15.2 2.9 15 2.5 14.7 2.2L13 0.5C12.7 0.2 12.3 0 11.8 0H11.7V3.5H15.2L15.2 3.4Z" class="color-svg"></path><path d="M10.7 12.1C10.8 11.8 11 11.4 11.3 11.2L15.2 7.2V4.7H11.1C10.8 4.7 10.5 4.4 10.5 4.1V0H1.8C0.8 0 0 0.8 0 1.8V18.2C0 19.2 0.8 20 1.8 20H13.5C14.4 20 15.2 19.2 15.2 18.2V13.9L14.6 14.5C14.3 14.8 14 14.9 13.7 15.1L11.8 15.7 11.5 15.7C11.1 15.7 10.6 15.5 10.3 15.1 10.1 14.7 10 14.2 10.1 13.8L10.7 12.1ZM2.9 10.5H8.8C9.1 10.5 9.4 10.8 9.4 11.1 9.4 11.5 9.1 11.7 8.8 11.7H2.9C2.6 11.7 2.3 11.5 2.3 11.1 2.3 10.8 2.6 10.5 2.9 10.5ZM2.3 8.8C2.3 8.5 2.6 8.2 2.9 8.2H8.8C9.1 8.2 9.4 8.5 9.4 8.8 9.4 9.1 9.1 9.4 8.8 9.4H2.9C2.6 9.4 2.3 9.1 2.3 8.8ZM2.3 13.5C2.3 13.2 2.6 12.9 2.9 12.9H8.8C9.1 12.9 9.4 13.2 9.4 13.5 9.4 13.8 9.1 14.1 8.8 14.1H2.9C2.6 14.1 2.3 13.8 2.3 13.5ZM11.1 16.4C11.4 16.4 11.7 16.7 11.7 17 11.7 17.3 11.4 17.6 11.1 17.6H7.6C7.3 17.6 7 17.3 7 17 7 16.7 7.3 16.4 7.6 16.4H11.1ZM2.9 7C2.6 7 2.3 6.8 2.3 6.4 2.3 6.1 2.6 5.9 2.9 5.9H11.1C11.4 5.9 11.7 6.1 11.7 6.4 11.7 6.8 11.4 7 11.1 7H2.9Z" class="color-svg"></path><path d="M11.8 12.6L11.3 14.1C11.2 14.4 11.4 14.6 11.6 14.5L13.2 14 11.8 12.6Z" class="color-svg"></path><path d="M16.3 7.8L12.5 11.6 14.1 13.3C15 12.4 16.7 10.7 18 9.4L16.3 7.8Z" class="color-svg"></path><path d="M19.7 6.1C19.4 5.9 19.1 5.8 18.8 5.8 18.5 5.8 18.2 5.9 18 6.1L17.1 7 18.8 8.6C19.3 8.2 19.6 7.8 19.7 7.8 20.1 7.3 20.1 6.6 19.7 6.1Z" class="color-svg"></path><path d="M19.7 7.8C19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8Z" class="color-svg"></path><path d="M19.7 7.8C19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8 19.7 7.8Z" class="color-svg"></path><path d="M19.7 7.8C19.7 7.8 19.7 7.8 19.7 7.8V7.8Z" class="color-svg"></path></g>
            <defs><clipPath><rect width="20" height="20" fill="white"></rect></clipPath></defs>
          </svg>
          <b>{{item.creator_name}}</b>
        </h4>
        <span style="width:90px" v-if="new Date(item.taskDateFrom).toLocaleDateString() !== 'Invalid Date' && item.taskDateFrom !== null">{{new Date(item.taskDateFrom).toLocaleDateString()}} {{new Date(item.taskDateTo).toLocaleTimeString().slice(0,-3)}}</span>
      </div>
      <p style="word-wrap: break-word;">{{item.description}}</p>
    </a>


    <h2 v-if="history.length !== 0">{{$root.langText.input.historyCommunication}}</h2>
    <a class="form__chat"
      v-for="item in history"
      :key="item.id"
      :data-id="item.id"
      @click.prevent="openPopup(item, 'history')"
    >
      <h3 style="margin-right:90px;font-size: 10px;">{{item.manager_name}}</h3>
      <p style="word-wrap: break-word;">{{item.description}}</p>
      <span style="position: absolute;right:10px;top:-4px" v-if="new Date(item.date_next_contact).toLocaleDateString() !== 'Invalid Date' && item.date_next_contact !== null">{{new Date(item.date_next_contact).toLocaleDateString()}} {{new Date(item.date_next_contact).toLocaleTimeString().slice(0,-3)}}</span>
    </a>


    <a href="#"
      style="color: #004d9d;margin-top: 20px;display:block"
      v-if="quantityList > +params.start && quantityList >= 20"
      @click.prevent="nextList">{{$root.langText.more}} {{(+quantityList - +params.start) >= 20 ? '20' : +quantityList - +params.start}}
    </a>


    <transition name="fade">
      <InformationPlates v-if="popup.flag" :idClient="id.client_id" :data="popup" @close="closePopup" />
    </transition>
  </div>
</template>



<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .form__chat{
    position: relative;
  }
  .form__tasks span{
    width: 90px;
  }
</style>


<script>
import DataInput from "@/components/app/DataInput";
import InformationPlates from "@/components/leads/InformationPlates";


export default {
  props: ["id"],
  data: () => ({
    leadsId: null,
    popup: {info: '', mod: '', flag: false},


    tasks: '',
    history: '',

    contact_result: '',
    data: '',

    error: false,





    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    quantityList: '',

  }),

  async created() {
    if (this.$route.params.id && this.$route.params.id != "create") {
      if (this.id) {
        this.leadsId = this.id;
        var res = await this.$store.dispatch('getLeadsHistory', this.leadsId.client_id)
        this.quantityList = res.data.contacts_history_count
        this.tasks = res.data.tasks
        this.history = res.data.contacts_history
      }
    }
  },

  methods: {
    //date
    dataInput(data) {
      this[data.input] = data.date;
    },
    openPopup(data, mod){
      this.popup.mod = mod
      this.popup.info = data
      if(data.owner_type == 'clients_contacts_history' || mod == 'tasks'){
        this.popup.flag = true
      }
    },

    async closePopup(data){
      this.popup.flag = data
      var res = await this.$store.dispatch('getLeadsHistory', this.leadsId.client_id)
      this.quantityList = res.data.contacts_history_count
      this.tasks = res.data.tasks
      this.history = res.data.contacts_history

      this.$emit('updateData')
    },
    

    async sendForm(){

      var data = {
        date_next_contact: (new Date(this.data).getTime() / 1000).toFixed(0) == 'NaN' ? '' : (new Date(this.data).getTime() / 1000).toFixed(0),
        contact_result: this.contact_result || '',
        client_id: this.leadsId.client_id,
        owner_id: this.leadsId.client_id,
        owner_type: 'client'
      }

      if(this.contact_result){
        this.error = false
        await this.$store.dispatch('addContacts_hist', data)

        //update
        var res = await this.$store.dispatch('getLeadsHistory', this.leadsId.client_id)
        this.quantityList = res.data.contacts_history_count
        this.tasks = res.data.tasks
        this.history = res.data.contacts_history
        
        this.data = ''
        this.contact_result = ''
      } else{
        this.error = true
      }
    },


    async nextList(){
      this.params.start = +this.params.start + 20
      var res = await this.$store.dispatch('getLeadsHistoryChat', {id: this.leadsId.client_id, params: this.params})
      var app = this
      res.data.contacts_history.forEach(function(item, index){
        app.history.push(item)
      })
    }


  },

  components: {
    DataInput,
    InformationPlates 
  },
};
</script>